<template>
  <div class="demo-integration">
    <span class="copy-to-clipboard" @click="copyToClipboard">{{ $t('copy_to_clipboard') }}</span>
    <span class="demo-code">
      <pre><code>&lt;link href=&quot;{{assetUrl('css')}}&quot; rel=&quot;stylesheet&quot;/&gt;&lt;{{htmlElement}} api-host=&quot;{{apiHost}}&quot; api-endpoint=&quot;{{endpoint}}&quot; app-id=&quot;{{appId}}&quot; locale=&quot;{{locale}}&quot; map-width=&quot;{{mapWidth}}&quot; map-height=&quot;{{mapHeight}}&quot; map-zoom=&quot;{{mapZoom}}&quot; map-lon=&quot;{{mapLon}}&quot; map-lat=&quot;{{mapLat}}&quot; marker-height=&quot;{{markerHeight}}&quot;/&gt;&lt;script src=&quot;{{assetUrl('js')}}&quot;&gt;&lt;/script&gt;</code></pre>
    </span>

    <pre><code>&lt;link href=&quot;{{assetUrl('css')}}&quot; rel=&quot;stylesheet&quot; /&gt;

    &lt;{{htmlElement}}
      api-host=&quot;<span class="color-blue">{{apiHost}}</span>&quot;
      api-endpoint=&quot;<span class="color-pink font-bold">{{endpoint}}</span>&quot;
      app-id=&quot;<span class="color-blue">{{appId}}</span>&quot;
      locale=&quot;<span class="color-blue">{{locale}}</span>&quot;
      map-width=&quot;<span class="color-blue">{{mapWidth}}</span>&quot;
      map-height=&quot;<span class="color-blue">{{mapHeight}}</span>&quot;
      map-zoom=&quot;<span class="color-blue">{{mapZoom}}</span>&quot;
      map-lon=&quot;<span class="color-blue">{{mapLon}}</span>&quot;
      map-lat=&quot;<span class="color-blue">{{mapLat}}</span>&quot;
      marker-height=&quot;<span class="color-blue">{{markerHeight}}</span>&quot;
    /&gt;

    &lt;script src=&quot;{{assetUrl('js')}}&quot;&gt;&lt;/script&gt;</code></pre>
  </div>
</template>

<script>
import qs from 'qs'
import urlJoin from 'url-join'
import $ from 'jquery'

export default {
  props: {
    appId: {
      type: String,
      default: process.env.VUE_APP_ID || 'dataCycle-widget'
    },
    htmlElement: {
      type: String,
      default: process.env.VUE_APP_HTML_ELEMENT || 'data-cycle-widget'
    },
    locale: {
      type: String,
      default: process.env.VUE_APP_LOCALE || 'de'
    },
    apiEndpoint: {
      type: String,
      default: process.env.VUE_APP_API_ENDPOINT || ''
    },
    apiHost: {
      type: String,
      default: process.env.VUE_APP_API_HOST || process.env.BASE_URL
    },
    mapWidth: {
      type: String,
      default: '100%'
    },
    mapHeight: {
      type: String,
      default: '600px'
    },
    mapZoom: {
      type: Number,
      default: process.env.VUE_APP_MAP_ZOOM || 5
    },
    mapLon: {
      type: Number,
      default: process.env.VUE_APP_MAP_CENTER_LON || 12.2
    },
    mapLat: {
      type: Number,
      default: process.env.VUE_APP_MAP_CENTER_LAT || 48.4
    },
    markerHeight: {
      type: Number,
      default: 44
    }
  },
  computed: {
    endpoint() {
      let urlParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
      })

      if (
        urlParams &&
        urlParams.data_cycle_widget &&
        urlParams.data_cycle_widget.endpoint &&
        urlParams.data_cycle_widget.endpoint.length
      )
        return urlParams.data_cycle_widget.endpoint
      else return this.apiEndpoint
    }
  },
  methods: {
    assetUrl(type) {
      let url = urlJoin(
        window.location.protocol,
        window.location.host,
        window.location.pathname == '/' ? '' : window.location.pathname,
        type,
        'widget.' + type
      )
      return url
    },
    copyToClipboard(event) {
      event.preventDefault()

      let target = $(event.currentTarget)
      let text = $('.demo-code pre code').text()
      let inp = document.createElement('input')
      document.body.appendChild(inp)
      inp.value = text
      inp.select()
      document.execCommand('copy', false)
      inp.remove()

      target.text(this.$t('copied_to_clipboard')).addClass('copy-finished')

      setTimeout(
        function() {
          target.text(this.$t('copy_to_clipboard')).removeClass('copy-finished')
        }.bind(this),
        1000
      )
    }
  }
}
</script>
