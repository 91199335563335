import Vue from 'vue'
import Demo from './App.vue'
import i18n from '../i18n'

// plugins
import './assets/css/main.scss'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

Vue.config.productionTip = false
Demo.i18n = i18n

const mountEl = document.querySelector('#demo-integration')

new Vue({
  render: createElement => {
    const context = {
      props: { ...mountEl.dataset }
    }
    return createElement(Demo, context)
  }
}).$mount('#demo-integration')
